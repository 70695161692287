import { useBuilderNGN } from "./BuilderNGN";
import { unpack } from "../../utils/unpack";
import UploadCSV from "./UploadCSV";
import { SelectAutocomplete } from "../util/SelectAutocomplete";
import React from "react";

export const QuestionnaireDetails = () => {
  const { item, editQuestionnaireDetails } = useBuilderNGN();

  return (
    <div className='flex flex-col gap-4 bg-white ml-1 rounded min-h-[88vh] p-2 '>
      <div>
        <label htmlFor='name' className='block text-gray-700 font-medium'>
          Name:
        </label>
        <input
          type='text'
          id='name'
          name='name'
          onBlur={unpack(editQuestionnaireDetails("name"))}
          defaultValue={item?.name || ""}
          className='w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-sky-500'
        />
      </div>
      <div>
        <label htmlFor='name' className='block text-gray-700 font-medium'>
          Title:
        </label>
        <input
          type='text'
          id='title'
          name='title'
          defaultValue={item?.title || ""}
          onBlur={unpack(editQuestionnaireDetails("title"))}
          className='w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-sky-500'
        />
      </div>

      <div>
        <label htmlFor='name' className='block text-gray-700 font-medium'>
          Sub Text:
        </label>
        <textarea
          id='sub'
          name='sub'
          onBlur={unpack(editQuestionnaireDetails("subText"))}
          className='w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-sky-500'
          defaultValue={item?.subText || ""}
        />
      </div>
      <UploadCSV />
    </div>
  );
};
