import { CopyAction, ImportAction, RemoveAction } from "../util/ItemActions";
import { AccordionItem, TitleWithAction } from "../util/Accourdion";
import { Rule } from "./Rule";
import { RuleGroupAction } from "./Header/RuleGroupAction";
import { RuleGroupOwner, useBuilderNGN } from "../Questionaire/BuilderNGN";
import { cb } from "../../utils/cd";
import { QuestionRuleGroupEntity, RuleGroupEntity, RuleGroupKind } from "@Savus-Inc/questionnaire-types";
import { ComplexOperatorToggle } from "./ComplexOperatorToggle";
import { ComplexOperator } from "@Savus-Inc/dsl/dist/types";
import classNames from "classnames";
import { NoData } from "../util/Empty";
import { useMemo } from "react";

export const RuleGroups = ({
  groups,
  kind,
  groupType,
  ownerId,
}: {
  ownerId?: string;
  groupType: RuleGroupOwner;
  groups: RuleGroupEntity[];
  kind: RuleGroupKind;
}) => {
  const { removeRuleGroup, insertRule, updateRuleGroup, currentGroup, resort } = useBuilderNGN();

  const handleImport = (data: string, group: RuleGroupEntity) => {
    const parsedData = JSON.parse(data);
    const updatedRules = parsedData.rules.map((rule: {}) => ({
      ...rule,
      op: ComplexOperator.And,
    }));

    updateRuleGroup(groupType, group, "rules", ownerId)([...group.rules, ...updatedRules]);
  };

  return (
    <div className={"flex flex-col gap-2"}>
      {groups
        .sort((a, b) => a.partOrder - b.partOrder)
        .map((item, idx) => (
          <div
            className={classNames(" border-t border-gray-200", {
              "mt-10": idx && item.op === ComplexOperator.Standalone,
            })}
          >
            <AccordionItem
              initial={true}
              title={
                <TitleWithAction
                  maxWidth='300px'
                  title={
                    <div className={"flex gap-2 w-80 "}>
                      {item.kind !== RuleGroupKind.Knockout && (
                        <ComplexOperatorToggle
                          onClick={updateRuleGroup(groupType, item, "op", ownerId)}
                          op={item.op}
                          omit={!idx ? [ComplexOperator.Or, ComplexOperator.And] : []}
                        />
                      )}
                      <div onClick={e => e.stopPropagation()} className='flex items-center gap-2'>
                        <ImportAction onImport={data => handleImport(data, item)} />
                        <CopyAction value={JSON.stringify(item)} />
                      </div>
                    </div>
                  }
                  add={cb(insertRule, groupType, item, ownerId)}
                  remove={cb(removeRuleGroup(groupType, kind, ownerId), item.id)}
                  move={
                    groups.length > 1
                      ? d =>
                          resort("ruleGroup", {
                            groupId: currentGroup?.id || "",
                            questionId: currentGroup?.id || "",
                            ruleGroupId: item.id,
                            kind,
                          })(groups || [], item.partOrder, d)
                      : undefined
                  }
                />
              }
              key={`${item.id}-${idx}`}
            >
              {!item.op ||
                (item.op === ComplexOperator.Standalone && (
                  <div className={"flex flex-col gap-2"}>
                    <RuleGroupAction
                      kind={kind}
                      onChange={updateRuleGroup(groupType, item, "actionKind", ownerId)}
                      value={item.actionKind}
                    />
                  </div>
                ))}

              <div className={"flex flex-col gap-2 mt-2 border-t border-t-gray-300 pt-2"}>
                {item.rules.length ? (
                  <RuleGroup group={item as QuestionRuleGroupEntity} owner={groupType} ownerId={ownerId} />
                ) : (
                  <NoData
                    message={
                      "Group does not have any rules defined which means that this group will be ignored at runtime until rules are provided "
                    }
                  />
                )}
              </div>
            </AccordionItem>
          </div>
        ))}
    </div>
  );
};

export const RuleGroup = ({
  group,
  owner,
  ownerId,
}: {
  group: QuestionRuleGroupEntity;
  owner: RuleGroupOwner;
  ownerId?: string;
}) => {
  const { removeRule, editRule, context } = useBuilderNGN();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ctx = useMemo(() => context(), []);

  return (
    <div className={"flex flex-col bg-white flex-1 gap-2"}>
      {group.rules.map((item, idx) => (
        <div className={"flex gap-2 w-max rounded justify-between items-start px-2 py-[2px]"} key={`${item.id}-${idx}`}>
          <Rule rule={item} editRule={editRule(owner, group, ownerId)} context={ctx} idx={idx} />
          <RemoveAction remove={cb(removeRule, owner, group, item.id || "", ownerId)} />
        </div>
      ))}
    </div>
  );
};

/*
OWN Value integration OwnValues from questionnaire question values
*/
