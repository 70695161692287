import React from "react";
import Papa, { ParseResult } from "papaparse";
import { useBuilderNGN } from "./BuilderNGN";
import { CarrierQuestion, QuestionnaireQuestionEntity, RuleGroupKind } from "@Savus-Inc/questionnaire-types";
import { v4 } from "uuid";
import { ComplexOperator, ValueFunctions } from "@Savus-Inc/dsl/dist/types";
import { toast } from "react-toastify";
import { useAppState } from "../../state/State";

export const transformPathVal = (v: string) => {
  const handlePlural = (v: string) => {
    if (
      ["insureds", "locations", "buildings", "classes", "coverages", "questions", "losses", "additionalInterests"].some(
        w => v.includes(w) && !v.endsWith("]"),
      )
    ) {
      return [`${v.trim()}[]`];
    } else {
      return [v.trim()];
    }
  };
  if (v.includes("='Primary'")) {
    const str = v.substring(0, v.indexOf("["));
    return [handlePlural(str), "0"].flat(9);
  } else if (v.includes("='Additional'")) {
    const str = v.substring(0, v.indexOf("["));
    return [handlePlural(str), "__Additional"].flat(9);
  } else {
    return [handlePlural(v)].flat(9);
  }
};

type CSVNode = Record<"jsonNode" | "questionId" | "answerValue" | "answerLabel" | "questionCode" | "carrier", string>;
export const UploadMapping = ({ carrierId }: { carrierId: string }) => {
  const { item, bulkSetCarrierMapping, questions } = useBuilderNGN();
  const {
    state: { carriers },
  } = useAppState();
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file?.type?.toLowerCase()?.includes("json")) {
      const data: CarrierQuestion[] = JSON.parse(await file?.text());
      bulkSetCarrierMapping(
        carrierId,
        data
          .filter(v => !v.referencedQuestionId || questions.some(a => a.id === v.referencedQuestionId))
          .map(v => {
            if (v.referencedQuestionId) {
              v.valueRules.map(vr => {
                if (vr.fn === ValueFunctions.KeyValue && !vr.condition) {
                  vr.condition = {
                    op: ComplexOperator.Standalone,
                    kind: RuleGroupKind.Knockout,
                    rules: [
                      {
                        op: ComplexOperator.Standalone,
                        value: {
                          fn: ValueFunctions.Unanswered,
                          args: [
                            {
                              ref: v.referencedQuestionId,
                            },
                            "False",
                          ],
                        },
                      },
                    ],
                  } as never;
                }

                return vr;
              });
            }
            v.jsonNode = v.jsonNode.map(v => {
              const hasClassesAndExposures =
                v.includes("classes[]") && v.includes("exposure[]") && !v.includes("questions[]");
              const hasBuildingAssignment = v.includes("locations[]") && v.includes("buildings[]");

              const hasCoverages = v.includes("coverages") && v.includes("questions[]");
              return v.map(k =>
                k === "locations[]" && hasBuildingAssignment
                  ? `locations[${
                      v.includes("quotesInfo") ? "locationId" : "id"
                    }={b17f01d5-9683-4708-bb74-7ecb94eb95b3}]`
                  : hasClassesAndExposures && k === "exposure[]"
                  ? "exposure"
                  : hasCoverages && k === "coverages"
                  ? "coverages[]"
                  : k,
              );
            });

            v.id = v4();
            return v;
          }),
      );
      return;
    }
    if (file) {
      Papa.parse(file, {
        header: true,
        complete(results: ParseResult<any>) {
          const carrierQuestionnaire = item.carrierQuestionnaires.find(c => c.carrierId === carrierId);
          const carrierName = carriers.find(c => c.id === carrierId)?.name;
          if (!carrierName || !carrierQuestionnaire) {
            return;
          }

          if (window.confirm("Do you want to remove all previous mappings for the carrier?")) {
            carrierQuestionnaire.questions = [];
          }
          const csvData = results.data as CSVNode[];
          const questions: Record<string, QuestionnaireQuestionEntity> = item.groups
            .flatMap(g => g.questions)
            .reduce((p, c) => ({ ...p, [c?.question?.externalId || ""]: c }), {});

          let detectedNewMapping = 0;
          const questionMaps = csvData
            .filter(v => {
              return (
                v.questionId &&
                v.carrier === carrierName &&
                !carrierQuestionnaire.questions.some(m => m.referencedQuestionId === questions[v.questionId]?.id)
              );
            })
            .reduce(
              (p, c) => {
                if (p[c.questionId]) {
                  p[c.questionId].push(c);
                } else {
                  p[c.questionId] = [c];
                }
                return p;
              },
              {} as Record<string, CSVNode[]>,
            );

          Object.entries(questionMaps).forEach(([_, v]) => {
            const master = v.find(v => v.jsonNode && v.jsonNode !== "n/a");

            if (
              !master ||
              !questions[master.questionId] ||
              (master.jsonNode.endsWith(".code") && !master.questionCode) ||
              !master.jsonNode.trim()
            ) {
              return;
            }

            let valueRules: CarrierQuestion["valueRules"] = [];
            // ownValues
            if (v.length > 1 && v.some(v => v.answerValue.trim() !== v.answerLabel.trim())) {
              valueRules.push(
                ...v
                  .filter(v => v.answerValue)
                  .map(v => ({
                    fn: master.questionCode ? ValueFunctions.KeyValue : ValueFunctions.Value,
                    args: master.questionCode
                      ? [
                          !carrierName.toLowerCase().includes("ubb") ? "questionId" : "code",
                          master.questionCode,
                          !carrierName.toLowerCase().includes("ubb") ? "answer" : "value",
                          v.answerValue,
                        ]
                      : [v.answerValue],
                    condition: {
                      op: ComplexOperator.Standalone,
                      kind: RuleGroupKind.Knockout,
                      rules: [
                        {
                          op: ComplexOperator.Standalone,
                          value: {
                            fn: ValueFunctions.Is,
                            args: [
                              {
                                ref: questions[v.questionId].id,
                              },
                              v.answerLabel,
                            ],
                          },
                        },
                      ],
                    },
                  })),
              );
            } else if (master.questionCode) {
              valueRules.push({
                fn: ValueFunctions.KeyValue,
                args: [
                  !carrierName.toLowerCase().includes("ubb") ? "questionId" : "code",
                  master.questionCode,
                  !carrierName.toLowerCase().includes("ubb") ? "answer" : "value",
                  { ref: questions[master.questionId].id },
                ],
                condition: {
                  op: ComplexOperator.Standalone,
                  kind: RuleGroupKind.Knockout,
                  rules: [
                    {
                      op: ComplexOperator.Standalone,
                      value: {
                        fn: ValueFunctions.Unanswered,
                        args: [
                          {
                            ref: questions[master.questionId].id,
                          },
                          "False",
                        ],
                      },
                    },
                  ],
                } as never,
              });
            } else {
              valueRules.push({
                fn: ValueFunctions.Value,
                args: [
                  {
                    ref: questions[master.questionId].id,
                  },
                ],
              });
            }

            // noinspection RegExpRedundantEscape
            const map: CarrierQuestion = {
              id: v4(),
              referencedQuestionId: questions[master.questionId].id,
              jsonNode:
                master?.jsonNode?.split(",").map(
                  jsonNode =>
                    jsonNode
                      ?.split(".")
                      .flatMap(transformPathVal)
                      .filter(v => v !== "code") || [],
                ) || [],
              valueRules,
            };

            if (!carrierQuestionnaire.questions.some(v => v.referencedQuestionId === map.referencedQuestionId)) {
              detectedNewMapping += 1;
              carrierQuestionnaire.questions.push(map);
            }
          });

          const payload = carrierQuestionnaire.questions.map(v => {
            v.jsonNode = v.jsonNode.map(v => {
              const hasClassesAndExposures =
                v.includes("classes[]") && v.includes("exposure[]") && !v.includes("questions[]");
              const hasBuildingAssignment = v.includes("locations[]") && v.includes("buildings[]");

              const hasCoverages = v.includes("coverages") && v.includes("questions[]");
              return v.map(k =>
                k === "locations[]" && hasBuildingAssignment
                  ? `locations[${
                      v.includes("quotesInfo") ? "locationId" : "id"
                    }={b17f01d5-9683-4708-bb74-7ecb94eb95b3}]`
                  : hasClassesAndExposures && k === "exposure[]"
                  ? "exposure"
                  : hasCoverages && k === "coverages"
                  ? "coverages[]"
                  : k,
              );
            });

            return v;
          });

          if (
            !window.confirm(
              `You are about to add ${detectedNewMapping} mappings for ${carrierName}, do you wish to continue?`,
            )
          ) {
            return toast.info("Action aborted");
          }
          bulkSetCarrierMapping(carrierId, payload);

          toast.info(`Detected ${detectedNewMapping} new mappings.`);
        },
      });
    }
  };

  return (
    <div className='flex gap-2 items-center'>
      <a
        className='w-1/2 px-3 rounded-xl border border-gray-200 text-sky-600 py-1.5'
        href={`data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(item.carrierQuestionnaires.find(q => q.carrierId === carrierId)?.questions || []),
        )}`}
        download={`${Date.now()}-questionnaire-${item.title}-data-mapping.json`}
      >
        Download
      </a>
      <div className='w-full max-w-lg flex flex-col justify-center relative'>
        <input
          type='file'
          accept='.csv,.json'
          onChange={handleFileChange}
          className='block w-full text-sm text-gray-500 bg-gray-50 px-3 py-1 rounded-xl
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-violet-700
              hover:file:bg-violet-100
          '
        />
      </div>
    </div>
  );
};
