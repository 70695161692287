import { useBuilderNGN } from "../Questionaire/BuilderNGN";
import React, { useCallback, useMemo } from "react";
import { unpack } from "../../utils/unpack";
import { ValueInput } from "../Questionaire/ValueInput";
import { AddAction, CopyAction, ImportAction } from "../util/ItemActions";
import { remove } from "../../utils/arr-utils";
import { JsonNodeValue } from "./JsonNodeValue";
import { CarrierDefault } from "@Savus-Inc/questionnaire-types";
import { DateValueKind, Val, Value } from "@Savus-Inc/dsl/dist/types";
import { NoData } from "../util/Empty";
import { SelectAutocomplete } from "../util/SelectAutocomplete";

export const CarrierMappingDefaults = () => {
  const { item, setCarrierMappingItem, carrierMappingDefaultId, carrierId } = useBuilderNGN();
  const carrier = useMemo(() => {
    return item.carrierQuestionnaires.find(c => c.carrierId === carrierId);
  }, [carrierId, item]);

  const [c, idx] = useMemo(() => {
    const idx = (carrier?.defaults || []).findIndex(b => b.id === carrierMappingDefaultId);
    return [(carrier?.defaults || [])[idx], idx];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrier, carrierMappingDefaultId, item]);

  const updateItem = useCallback(
    (key: keyof CarrierDefault, item: CarrierDefault) =>
      <T = unknown,>(value: T) => {
        if (!carrierId) return;
        if (key === "value") {
          item.isList = Array.isArray((value as Value).value);
        }

        setCarrierMappingItem(carrierId, idx, ["defaults", { ...item, [key]: value }]);
      },
    [carrierId, setCarrierMappingItem, idx],
  );

  if (!c) {
    return <NoData />;
  }

  return (
    <div className={"flex flex-col gap-2 border rounded p-2 px-4 relative shadow bg-white"} key={JSON.stringify(c)}>
      <div className={"justify-self-end justify-end flex w-full"}>
        <CopyAction value={JSON.stringify(c)} />
        <ImportAction
          onImport={data => {
            setCarrierMappingItem(carrierId as string, idx, ["defaults", { ...JSON.parse(data), id: c.id }]);
          }}
        />
      </div>
      <label className={"flex items-center gap-4 py-2"}>
        <div className={"text-gray-800 font-semibold w-24"}>JSON Node</div>
        <div>
          <JsonNodeValue item={c} items={carrier?.defaults || []} onChange={updateItem("jsonNode", c)} />
          <span className={"text-xs text-gray-700 text-center"}>
            <i>(Used for remap to carrier expected payload)</i>
          </span>
        </div>
      </label>
      <label className={"flex items-center gap-4 border-b py-2"}>
        <div className={"text-gray-800 font-semibold w-24"}>Value</div>
        <div className={"whitespace-nowrap flex flex-col text-center"}>
          <div>
            <SelectAutocomplete
              suggestions={[...Object.values(Val), "code-value"]
                .map(g => [g, `${g}[]`])
                .flat()
                .map(g => ({ label: g, value: g }))}
              value={c.isList ? `${c.value?.kind}[]` : c.value?.kind}
              styles='justify-between border-gray-300 rounded px-1 py-0.5 focus:outline-none focus:border-sky-500 border'
              onSelect={v => {
                const isList = v.endsWith("[]");

                switch (v) {
                  case Val.String:
                  case `${Val.String}[]`:
                    updateItem("value", c)({ value: isList ? [""] : "", kind: Val.String });
                    break;
                  case Val.Number:
                  case `${Val.Number}[]`:
                    updateItem("value", c)({ value: isList ? [0] : 0, kind: Val.Number });
                    break;
                  case Val.Bool:
                  case `${Val.Bool}[]`:
                    updateItem(
                      "value",
                      c,
                    )({
                      value: isList ? [true] : true,
                      kind: Val.Bool,
                    });
                    break;
                  case Val.Date:
                  case `${Val.Date}[]`:
                    updateItem(
                      "value",
                      c,
                    )({
                      value: isList
                        ? [{ value: { from: new Date().toJSON() }, kind: DateValueKind.Single }]
                        : { value: { from: new Date().toJSON() }, kind: DateValueKind.Single },
                      kind: Val.Date,
                    });
                    break;

                  case "code-value": {
                    updateItem("value", c)({ value: { code: "", value: "" }, kind: Val.String });
                    break;
                  }
                }
              }}
            />
          </div>
          <span className={"text-xs text-gray-700"}>
            <i>(Type of the value)</i>
          </span>
        </div>
        <div className={"flex flex-col text-center"}>
          <div className={"flex gap-1"}>
            {c.value && c.isList ? (
              <div className={"flex flex-wrap gap-2"}>
                {c.value.value.map((v, i) => (
                  <div key={i} className={"flex w-max items-center relative"}>
                    <ValueInput
                      kind={c.value?.kind || Val.String}
                      value={{ value: v, kind: c.value?.kind } as never}
                      onChange={unpack(v => {
                        (c.value as Value<true>).value[i] = (v as any).value;

                        updateItem("value", c)(c.value);
                      })}
                      onlySingleDate
                    />

                    <div
                      className={
                        "scale-75 absolute -top-2 -right-1 rounded-full bg-white text-red-500 px-1.5 shadow border border-red-500 font-bold cursor-pointer"
                      }
                      onClick={() => {
                        remove((c.value as Value<true>).value as never, i);
                        updateItem("value", c)(c.value);
                      }}
                    >
                      X
                    </div>
                  </div>
                ))}
                <AddAction
                  add={() => {
                    let v: Value["value"];
                    switch (c.value?.kind as Val) {
                      case Val.String:
                        v = "";
                        break;
                      case Val.Number:
                        v = 0;
                        break;
                      case Val.Bool:
                        v = true;
                        break;
                      case Val.Date:
                        v = {
                          kind: DateValueKind.Single,
                          value: {
                            from: new Date().toJSON(),
                          },
                        } as never;
                        break;
                    }

                    c.value?.value?.push(v as never);
                    updateItem("value", c)(c.value);
                  }}
                />
              </div>
            ) : (c.value?.value as any).code !== undefined ? (
              <div className={"flex gap-4 items-center"}>
                <div className={"flex gap-1 items-center"}>
                  Code:
                  <input
                    type='text'
                    className='w-full border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
                    defaultValue={(c as any).value.value.code}
                    onBlur={unpack(v => {
                      (c as any).value.value.code = v;
                      updateItem("value", c)(c.value);
                    })}
                  />
                </div>
                <div className={"flex gap-1 items"}>
                  Value:
                  <input
                    key={(c as any).value.value.value}
                    type='text'
                    className='w-full border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
                    defaultValue={(c as any).value.value.value}
                    onBlur={unpack(v => {
                      (c as any).value.value.value = v;
                      updateItem("value", c)(c.value);
                    })}
                  />
                </div>
              </div>
            ) : (
              <ValueInput
                kind={c.value?.kind || Val.String}
                value={c.value as never}
                onChange={unpack(updateItem("value", c))}
                onlySingleDate
              />
            )}
          </div>

          <span className={"text-xs text-gray-700 whitespace-nowrap"}>
            <i>(Value used in question mapping/submission)</i>
          </span>
        </div>
      </label>
    </div>
  );
};
