import { useCallback, useReducer } from "react";
import { ComplexOperatorToggle } from "./ComplexOperatorToggle";
import { OpUI } from "../Op/OpUI";
import { QuestionContext } from "../../types/QuestionContext";
import { ComplexOperator, Op, Rule as R, Val, ValueFunctions } from "@Savus-Inc/dsl/dist/types";

export const Rule = ({
  rule,
  editRule,
  context,
  idx,
}: {
  rule: R;
  context: QuestionContext;
  editRule: (rule: R, key: keyof R) => (val: R[typeof key]) => void;
  idx?: number;
}) => {
  const [not, toggle] = useReducer(p => {
    editRule(rule, "not")(!p);
    return !p;
  }, !!rule.not);

  const [op, setop] = useReducer((_: ComplexOperator | null, val: ComplexOperator | null) => {
    if (val) {
      editRule(rule, "op")(val);
    }

    return val;
  }, rule.op);

  const updateRule = useCallback(
    (args: Op["args"]) => {
      if (rule.value.fn === ValueFunctions.Evaluate && !args[0]) {
        editRule(rule, "value")({ ...rule.value, args: [] });
      } else {
        editRule(rule, "value")({ ...rule.value, args });
      }
    },
    [editRule, rule],
  );

  const updateFn = useCallback(
    (fn: ValueFunctions) => {
      editRule(rule, "value")({ ...rule.value, fn, args: [] });
    },
    [editRule, rule],
  );

  return (
    <div className={"flex items-center p-0.5 gap-3 border py-2 px-3"}>
      {!!idx && op && <ComplexOperatorToggle onClick={setop} op={op} omit={[ComplexOperator.Standalone]} />}
      <label className='inline-flex items-center cursor-pointer '>
        <input type='checkbox' checked={not} className='sr-only peer' onChange={toggle} />
        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-500"></div>
      </label>
      <OpUI op={rule.value} update={updateRule} updateFunction={updateFn} context={context} resolvingType={Val.Bool} />
    </div>
  );
};
