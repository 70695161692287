import React, { useState } from "react";
import { Tag } from "../Rules/Tag";

interface Option {
  value: string;
  label: string;
}

interface MultiSelectProps {
  options: Option[];
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({ options, value: inV, onChange, placeholder = "Select options" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const value = inV || [];

  const handleOptionClick = (optionValue: string) => {
    const newValue = (Array.isArray(value) ? value : [value]).includes(optionValue)
      ? (Array.isArray(value) ? value : [value]).filter(val => val !== optionValue)
      : [...(Array.isArray(value) ? value : [value]), optionValue];
    onChange(newValue);
  };

  return (
    <div className='relative'>
      <button
        type='button'
        className='bg-white border border-gray-300 text-gray-700 py-2 px-4 rounded-md w-full flex justify-between items-center focus:outline-none max-w-[70vw]'
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={"flex gap-1 flex-wrap w-full"}>
          {(value?.length || []) === 0
            ? placeholder
            : (Array.isArray(value) ? value : [value]).map(val => (
                <div key={val} className={"max-w-fit overflow-hidden"}>
                  <Tag label={options.find(opt => opt.value === val)?.label || ""} color={"bg-gray-800"} />
                </div>
              ))}
        </div>

        <svg className='h-5 w-5 fill-current' viewBox='0 0 20 20'>
          <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
        </svg>
      </button>

      {isOpen && (
        <div className='absolute top-full left-0 bg-white border border-gray-300 rounded-md shadow-lg mt-2 w-full'>
          <ul>
            {options.map(option => (
              <li
                key={option.value}
                className={`py-1 px-2 cursor-pointer hover:bg-gray-100 ${
                  value.includes(option.value) ? "bg-sky-100" : ""
                }`}
                onClick={() => handleOptionClick(option.value)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
