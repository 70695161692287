import React, { createContext, useCallback, useContext, useMemo } from "react";
import { OpArgumentDisplay } from "./OpArgumentDisplay";
import { QuestionContext } from "../../types/QuestionContext";
import { getAllRefs } from "../Rules/utils/getAvailableRefs";
import { remove } from "../../utils/arr-utils";
import { Op, OpArgument, OpSignature, Ref, Val, ValueFunctions } from "@Savus-Inc/dsl/dist/types";
import { getFunctionSignature } from "@Savus-Inc/dsl/dist/utils";
import { SelectAutocomplete } from "../util/SelectAutocomplete";

function resolveOpValueSignature(op: Op, context: QuestionContext): Ref | undefined {
  if (!op?.fn) {
    return undefined;
  }
  const [arg] = op.args;

  if (!arg) {
    return;
  }

  if ((arg as Op).fn) {
    return resolveOpValueSignature(arg as Op, context);
  }

  if (typeof arg === "object" && "ref" in arg) {
    const { ref } = arg;
    if (ref) {
      const all = getAllRefs(context);
      return all.find(v => v.referenceId === ref);
    }
  }
}

const OpUIComponent = () => {
  const { updateFunction, op } = useOp();
  const availableFunction = useMemo(() => Object.values(ValueFunctions).sort(), []);

  if (!op?.fn) {
    return <></>;
  }

  return (
    <div className={"flex items-start flex-col gap-1"}>
      <SelectAutocomplete
        onSelect={updateFunction}
        suggestions={availableFunction.map(v => ({ label: v, value: v }))}
        value={op.fn}
      />
      <div className={"flex items-start gap-4 flex-wrap w-fit max-w-[50vw]"}>{<OpArgumentDisplay />}</div>
    </div>
  );
};

type OpUIT = {
  stack: Op[];
  op: Op;
  updateArg: (idx: number) => (val: OpArgument | Op) => void;
  updateFunction: (fn: Op["fn"]) => void;
  context: QuestionContext;
  signature: OpSignature;
  ref?: Ref;
  parent?: Op;
  resolvingType?: Val;
  addArg: () => void;
};

const OpUIContext = createContext({} as OpUIT);
export const useOp = (): OpUIT => useContext(OpUIContext);

export function OpUI({
  op,
  update,
  updateFunction,
  context,
  stack = [],
  resolvingType: rt,
}: {
  op: Op;
  update: (op: Op["args"]) => void;
  updateFunction: (fn: Op["fn"]) => void;
  context: QuestionContext;
  stack?: Op[];
  argIdx?: number;
  resolvingType?: Val;
}) {
  const [signature, ref] = useMemo(() => {
    if (!op) return [undefined, undefined];

    const ref = resolveOpValueSignature(op, context);
    return [getFunctionSignature(op.fn, ref), ref];
  }, [context, op]);

  const parent = useMemo(() => stack[stack.length - 1], [stack]);

  const updateArg = useCallback(
    (idx: number) => (val: OpArgument | Op) => {
      if (!val && op.args.length > (signature?.maxArgs || 1)) {
        remove(op.args, idx);
      } else {
        const value = val as OpArgument;
        if (
          value &&
          // eslint-disable-next-line
          value.toString().split(/[,\.]/).length > 1 &&
          window.confirm("Detected coma or dot separated values, Do you wish to split them?")
        ) {
          // eslint-disable-next-line
          const [v, ...rest] = value.toString().split(/[,\.]/);
          op.args[idx] = v;
          op.args.push(
            ...rest.slice(0, signature?.maxArgs ? signature.maxArgs - (idx + 1) : rest.length).map(v => v.trim()),
          );
        } else {
          op.args[idx] = val;
        }
      }

      op.args = op.args.filter(Boolean);
      update(op.args);
    },
    [op, signature, update],
  );

  const addArg = useCallback(() => {
    op.args.push(null as any);
    update(op.args);
  }, [op, update]);

  if (!op || !signature) {
    return <></>;
  }

  return (
    <OpUIContext.Provider value={{ signature, updateArg, updateFunction, context, op, stack, ref, parent, addArg }}>
      <OpUIComponent />
    </OpUIContext.Provider>
  );
}
